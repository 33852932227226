









































  import Vue from 'vue'
  import Sidebar from '../components/sidebar'
  import { mapActions, mapMutations } from 'vuex'
  import { ACTIONS, MUTATIONS } from '../store/consts'
  import ThemePreviewLogin from '../components/areas/ThemePreviewLogin.vue'
  import type { EditableTheme } from '../types'
  import { StoreThemeInput, Theme } from '@simpl/core/types/graphql'
  import { editableThemeToStoreThemeInput, uploadThemeAppModuleImages, uploadThemeAssets } from '../utils'
  import { STORE_THEME } from '../graphql'
  import ThemePreviewDashboard from '../components/areas/ThemePreviewDashboard.vue'

  const lightenDarkenColor = (color: string, amount: number) => {
    let usePound = false

    if (color.startsWith('#')) {
      color = color.slice(1)
      usePound = true
    }

    const num = parseInt(color, 16)

    let r = (num >> 16) + amount

    if (r > 255) r = 255
    else if (r < 0) r = 0

    let b = ((num >> 8) & 0x00FF) + amount

    if (b > 255) b = 255
    else if (b < 0) b = 0

    let g = (num & 0x0000FF) + amount

    if (g > 255) g = 255
    else if (g < 0) g = 0

    return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16)
  }

  export default Vue.extend({
    components: {
      Sidebar
    },

    props: {
      id: Number
    },

    data () {
      return {
        loading: true,
        area: 'appearance',
        areaViews: {
          appearance: ThemePreviewLogin,
          colors: ThemePreviewLogin,
          images: ThemePreviewLogin,
          fonts: ThemePreviewLogin,
          dashboard: ThemePreviewDashboard
        } as Record<string, (typeof Vue) | null>,

        styleElement: null! as HTMLStyleElement,

        uploading: false,
        progress: 0
      }
    },

    computed: {
      areaComponent (): typeof Vue | null {
        return this.areaViews[this.area]
      },
      remoteThemes (): Theme[] {
        return this.$store.state.theme.remoteThemes
      },
      themes (): EditableTheme[] {
        return this.$store.state.theme.themes
      },
      theme (): EditableTheme | undefined {
        return this.$store.state.theme.theme
      },
      domainId (): number {
        return parseInt(this.id || this.$store.state.auth.user?.active_domain?.id || 0)
      }
    },

    watch: {
      theme: {
        handler: 'updateThemeStyle',
        deep: true
      }
    },

    async mounted () {
      await this.fetchRemoteThemes()
    },

    beforeDestroy () {
      this.styleElement?.parentElement?.removeChild(this.styleElement)
    },

    methods: {
      ...mapActions('theme', {
        getThemes: ACTIONS.GET_THEMES
      }),

      async fetchRemoteThemes (fresh = true) {
        if (fresh) {
          this.loading = true
        }

        await this.getThemes(this.domainId)

        this.updateThemeStyle()

        this.loading = false
      },

      hex2Rgb (hex: string): { r: number, g: number, b: number } | null {
        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
        return result
          ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16)
          }
          : null
      },
      getContrastingTextColor (hex: string) {
        const rgb = this.hex2Rgb(hex)!
        const contrast = (Math.round(rgb.r * 299) + Math.round(rgb.g * 587) + Math.round(rgb.b * 114)) / 1000
        return contrast >= 128 ? '#000000' : '#ffffff'
      },

      updateThemeStyle () {
        if (!this.theme) {
          return
        }

        if (!this.styleElement) {
          this.styleElement = document.createElement('style')
          this.styleElement.setAttribute('type', 'text/css')
          this.styleElement.id = 'theme-edit-style'
          document.head.appendChild(this.styleElement)
        }

        const colorOnPrimary = this.getContrastingTextColor(this.theme.colors.primary!)
        const alternativeBackgroundColor = lightenDarkenColor(this.theme.variables.background!, this.theme.dark ? 30 : -30)

        let styleContent = ''
        if (this.theme.fontUrl) {
          const fontUrl = this.theme.fontUrl
          const fontHeadingUrl = this.theme.useDifferentFontForHeading && this.theme.fontHeadingUrl
            ? this.theme.fontHeadingUrl
            : fontUrl
          styleContent += `@font-face { font-family: 'ThemePreviewFont'; src: url('${fontUrl}'); }
@font-face { font-family: 'ThemePreviewFontHeading'; src: url('${fontHeadingUrl}'); }

.theme-builder--preview { font-family: 'ThemePreviewFont' !important; }
.theme-builder--preview .font-heading { font-family: 'ThemePreviewFontHeading' !important; }
`
        }

        styleContent += `.theme-builder--preview .heading { color: ${this.theme.colors.primary}; }

.theme-builder--preview .theme-builder--content {
  background-color: ${this.theme.variables.background};
  color: ${this.theme.variables.text} !important;
  border: 1px solid ${alternativeBackgroundColor};
}
.theme-builder--preview .v-sheet, .theme-builder--preview .v-alert.v-sheet.v-alert.v-sheet { background-color: ${this.theme.variables.sheetBackground}; color: ${this.theme.variables.text} !important; }
.theme-builder--preview .v-btn.fix-primary { color: ${colorOnPrimary} !important; }
.theme-builder--preview.theme-builder--preview .v-card.v-card { background-color: ${lightenDarkenColor(this.theme.variables.sheetBackground!, 8)} !important; }
.theme-builder--preview .v-card__title { background-color: ${this.theme.variables.sheetBackground} !important; }
.theme-builder--preview .v-card__actions { background-color: ${this.theme.variables.sheetBackground} !important; }

.theme-builder--preview .top-image { background-color: ${alternativeBackgroundColor}; }


`

        this.styleElement.innerText = ''
        this.styleElement.insertAdjacentText('beforeend', styleContent)
      },

      async performSave () {
        const activeThemes = this.themes.filter((theme: EditableTheme) => theme.active)

        this.uploading = true
        let completed = 0
        const progressPerModeAssets = 0.6 / activeThemes.length
        const progressPerModeAppModuleAssets = 0.2 / activeThemes.length
        const progressPerModeDataUpload = 0.2 / activeThemes.length
        const progressPerMode = 1 / activeThemes.length

        for (const mode of ['light', 'dark']) {
          const dark = mode === 'dark'
          const theme = this.themes.find(t => t.dark === dark)!
          const remoteTheme = this.remoteThemes.find(t => t.dark === dark)

          const uploadResult = await uploadThemeAssets(theme, (uploaded, total) => {
            this.progress = progressPerMode * completed + progressPerModeAssets * uploaded / total
          }, () => {
            this.progress = progressPerMode * completed + progressPerModeAssets
          }, remoteTheme)

          const appModuleResult = await uploadThemeAppModuleImages(theme, (uploaded, total) => {
            this.progress = progressPerMode * completed + progressPerModeAssets + progressPerModeAppModuleAssets * uploaded / total
          }, () => {
            this.progress = progressPerMode * completed + progressPerModeAssets + progressPerModeAppModuleAssets
          }, remoteTheme)

          const updateVariables: StoreThemeInput = {
            ...editableThemeToStoreThemeInput(theme, this.domainId),
            ...uploadResult,
            app_modules: appModuleResult
          }

          if (!remoteTheme && !theme.localLogoFile) {
            updateVariables.logo_id = this.remoteThemes[0].logo!.id!
          }

          await this.$apollo.mutate({
            mutation: STORE_THEME,
            variables: {
              input: updateVariables
            }
          })

          completed += 1

          this.progress = progressPerMode * completed
        }

        this.uploading = false

        await this.fetchRemoteThemes(false)
      }
    }
  })
