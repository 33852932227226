









































import Vue from 'vue'
import { EditableTheme } from '../../types'
import { IAppModuleThemable } from '@simpl/core/plugins/app-modules'
import DashboardTile from '@simpl/core/components/DashboardTile.vue'

const TILE_BREAKPOINTS = [{
  size: 520,
  col: 6
}, {
  size: 860,
  col: 4
}, {
  size: 1100,
  col: 3
}, {
  size: 1600,
  col: 2
}]

export default Vue.extend({
  name: 'ThemePreviewDashboard',

  components: {
    DashboardTile
  },

  props: {
    area: {
      type: String,
      default: undefined
    }
  },

  data () {
    return {
      colSize: 2
    }
  },

  computed: {
    theme (): EditableTheme {
      return this.$store.state.theme.theme
    },
    hoveredAppModule (): string | undefined {
      return this.$store.state.theme._hoveredAppModule
    },
    appModules (): IAppModuleThemable[] {
      return this.$appModules
        .themables()
        .sort((a, b) => (a.order || 0) - (b.order || 0))
    }
  },

  methods: {
    calculateColumns () {
      const w = document.querySelector('.theme-builder--preview')!.clientWidth
      let cols = 12
      for (const size of TILE_BREAKPOINTS) {
        if (w > size.size) {
          cols = Math.min(cols, size.col)
        }
      }
      this.colSize = cols
    }
  }
})
