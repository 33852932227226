













































































































































































import Vue from 'vue'
import { EditableTheme } from '../../types'

export default Vue.extend({
  name: 'ThemePreviewLogin',

  props: {
    area: {
      type: String,
      default: undefined
    }
  },

  data () {
    return {
      alertTypes: ['success', 'info', 'warning', 'error']
    }
  },

  computed: {
    theme (): EditableTheme {
      return this.$store.state.theme.theme
    }
  }
})
